<template>
    <div class="bottom">
        <div class="bottom_left">
            <div v-for="(item, i) in bottom" :key="i" class="bottom_textBox">
                <div class="bottom_text">{{ item.name }}</div>
                <div>
                    <div class="bottom_a_text" v-for="(ite, s) in item.list " :key="s" @click="bottomAText(ite)">{{ ite.name
                    }}</div>
                </div>
            </div>
        </div>
        <div class="bottom_content">
            <div class="bottom_content_title">联系我们</div>
            <div class="bottom_content_imgBox">
                <div class="bottomImg" @click="helpClick"></div>
                <div>添加微信，随时开启对话</div>
            </div>
            <div class="bottom_content_phone">官方服务热线&nbsp;&nbsp;&nbsp;&nbsp;400-123-1234</div>
        </div>
        <div class="bottom_luange">
            <div style="margin-bottom: 1.05vw;">eelhoe国际官网</div>
            <div>
                <el-select v-model="optionData">
                    <el-option v-for="item, i in option" :value="item.id" :label="item.name" :key="i"></el-option>
                </el-select>
            </div>
        </div>
        <div class="bottom_bottom">
            @ 2023 eelhoe 隐私政策 使用条款 粤ICP备2023888888号
        </div>
    </div>
</template>

<script>
import { bottom } from '../assets/apiData/homeNav'
export default {
    name: 'EelhoeoBottom',

    data() {
        return {
            bottom,
            option: [
                {
                    id: 0,
                    name: "eelhoe中国,中文"
                }
            ],
            optionData: 0,
        };
    },

    mounted() {
        this.bottom = bottom
    },

    methods: {
        helpClick() {
            if (this.$route.path != '/help') {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                this.$router.push('/help')
                this.$loading()
            }
        },
        bottomAText(val) {
            if (val.name == "关于eelhoe") {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                if (this.$route.path != '/translation') {
                    this.$router.push('/translation')
                    this.$loading()
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.bottom {
    width: 100%;
    height: 21.69vw;
    background: #222222;
    padding: 0 18.75vw;
    padding-top: 4.17vw;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .bottom_left {
        display: flex;
        justify-content: space-evenly;

        .bottom_textBox:nth-child(1) {
            margin-right: 8.35vw;
        }

        .bottom_text {
            font-size: calc(14vw*100/1920);
            font-weight: bold;
            text-align: left;
            color:#fff;
        }

        .bottom_a_text {
            color:#fff;
            font-size: calc(14vw*100/1920);
            font-weight: normal;
            margin-top: 1.05vw;
            cursor: pointer;
        }
    }

    .bottom_content {
        color:#fff;
        margin-left: 8vw;

        .bottom_content_title {
            font-size: calc(14vw*100/1920);
            color:#fff;
            font-weight: bold;
            margin-bottom: 1.05vw;
        }

        .bottom_content_imgBox {
            height: 5vw;
            display: flex;
            align-items: center;
            font-size: calc(12vw*100/1920);
            font-weight: normal;

            .bottomImg {
                width: 5vw;
                height: 5vw;
                background: #666;
                margin-right: 1.46vw;
                margin-bottom: 1.05vw;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

        }

        .bottom_content_phone {
            font-size: calc(14vw*100/1920);
            font-weight: normal;
        }

    }

    .bottom_luange {
        color:#fff;
        margin-left: 8vw;
    }

    .bottom_bottom {
        width: 100%;
        height: 2.82vw;
        border-top: 0.1vw solid #fff;
        margin-top: 3.34vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(14vw*100/1920);
        font-weight: normal;
        color:#fff;
    }

}
</style>