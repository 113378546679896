<template>
  <div id="app" @scroll="scrollEvent" ref="container">
    <top :topShow="appTopShow"></top>
    <router-view :scrollBottom="scrollBottom" />
    <div class="back" @click="backClick" v-show="returnTop"><img :src="backImg" alt=""></div>
    <bottom></bottom>

    <div class="loading">
      <div class="loading_img">
        <img src="./assets/iconImg/品牌故事logo1.svg" alt="" style="filter: brightness(0.5) contrast(1.5);">
      </div>
    </div>
  </div>
</template>
<script>
import top from './components/top.vue'
import bottom from './components/bottom.vue'
import backImg from './assets/iconImg/回顶.png'
export default {
  components: { top, bottom },
  data() {
    return {
      appTopShow: false,
      scrollBottom: 0,
      backImg: backImg,
      returnTop: false,
      loading: false
    }
  },
  mounted() {
    this.$refs.container.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollEvent(event) {
      if (event.target.scrollTop > 200) {
        this.appTopShow = true
      } else {
        this.appTopShow = false
      }
    },
    //监听页面滚动
    handleScroll(){
      if (this.$refs.container.scrollTop > 800) {
        this.returnTop = true
      } else {
        this.returnTop = false
      }
      if (this.$route.path == '/Story/about') {
        if (this.$refs.container.scrollTop >= this.$refs.container.scrollHeight * 0.6) {
          this.scrollBottom = 1
        } else {
          this.scrollBottom = 0
        }

      }
    },
    backClick(){
      document.getElementById("app").scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    loadingFun(){
      this.loading = !this.loading
    }
  }
}
</script>
<style lang="scss">
html,
body{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;
  .back{
    position: fixed;
    width: 3.3334vw;
    height: 3.3334vw;
    background: #000;
    top: 47%;
    right: 0;
    cursor: pointer;
    animation: moveLeft 0.2s linear;
    img {
      width: 100%;
      height: 100%;
    }
  }
  @keyframes moveLeft {
    0% {
      right: -1%;
    }
    100% {
      right: 0;
    }
  }

  .loading_img {
    width: 10vw;
    height: 10vw;
    cursor: pointer;

      //  background-image: url(./assets/newImage/品牌故事logo.png);
      //  animation: radar-scan 5s infinite linear;
      //  background-repeat: no-repeat;

    img {
      width: 100%;
      height: 100%;
      animation: spin 3s linear infinite;
      filter: invert(0%) sepia(50%) saturate(0%) hue-rotate(42deg);
       transform: rotate(360deg);  
      // box-shadow:0px 6px 18px 0px #EEF4EF;

    }
  }
  @keyframes radar-scan {
  0% {
    width: 0;
    height: 0;
    transform: rotate(0deg);
    // background-position: 0 0;
  }
  50% {
    width: 13vw;
    height: 13vw;
    opacity: 1;
    transform: rotate(360deg);
    // background-position: 100% 100%;
  }
  100%{
    width: -13vw;
    height: -13vw;
    opacity: 0;
    transform: rotate(0deg);
  }

}

  @keyframes spin {

    // 转圈动画
    // 0% { transform: rotate(0deg); }
    // 100% { transform: rotate(360deg); }
    0% {
      filter: invert(100%) sepia(0%) hue-rotate(0deg);
      // box-shadow:none;
    }

    50% {
      filter: invert(0%) sepia(50%) hue-rotate(100deg);
      // box-shadow:0px 6px 18px 0px #EEF4EF;
    }

    100% {
      filter: invert(100%) sepia(0%) hue-rotate(0deg);
      // box-shadow:none;
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #00000083;
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
  }
  .home_swiper .el-carousel__arrow--left {
    width: 4.2vw !important;
    height: 4.2vw !important;
    left: 12.5vw !important;
    border: 0.1vw solid #000;
    background: rgba(255, 255, 255, 0.6) !important;
  }

  .home_swiper .el-carousel__arrow--right {
    width: 4.2vw !important;
    height: 4.2vw !important;
    right: 12.5vw !important;
    border: 0.1vw solid #000;
    background: rgba(255, 255, 255, 0.6) !important;
  }

  .home_swiper .el-icon-arrow-left:before {
    color: #000;
    font-size: calc(24vw*100/1920);
  }

  .home_swiper .el-icon-arrow-right:before {
    color: #000;
    font-size: calc(24vw*100/1920);
  }

  .home_swiper .el-carousel__button {
    width: 4.2vw;
    height: 0.15vw;
    background: #000;
  }




  .num .el-input--suffix .el-input__inner {
    width: 6vw;
    border: none;
    padding-right: 30px;
  }

  .num .el-select .el-input .el-select__caret {
    color: #000;
    font-weight: bold;
  }

  .num .el-input-number {
    width: 6.15vw;
    height: 1.25vw;
    display: flex;
    align-items: center;
    line-height: normal;
  }

  .num .el-input-number .el-input__inner {
    width: 100%;
    height: 100%;
    padding: 0 1.25vw;
    border-radius: 2px;
    border: 0.1vw solid #000;
    font-size: calc(14vw*100/1920);
  }

  .num .el-input-number .el-icon-minus:before {
    color: #000;
    font-weight: bold;
  }

  .num .el-input-number .el-icon-plus:before {
    color: #000;
    font-weight: bold;
  }

  .num .el-input__inner:hover {
    border: 0.1vw solid #000 !important;
  }

  .num .el-input-number .el-input {
    height: 1.25vw;
  }

  .num .el-input-number__increase {
    width: 1.25vw;
    height: 1.25vw;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .num .el-input-number__decrease {
    width: 1.25vw;
    height: 1.25vw;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pageBox .el-pager {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: calc(14vw*100/1920);
  }

  .pageBox .el-pager li.active {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-bottom: 2px solid #000;
  }

  .pageBox .el-pager li:hover {
    color: #000;
  }

  .pageBox .el-icon-arrow-left:before {
    content: url(./assets/newImage/上一页.png)
  }

  .pageBox .el-icon-arrow-left:hover {
    content: url(./assets/iconImg/路径.png);
    // filter:  sepia(100%);
  }

  .pageBox .el-icon-arrow-right:before {
    content: url(./assets/newImage/下一页.png)
  }

  .pageBox .el-icon-arrow-right:hover {
    content: url(./assets/iconImg/路径1.png);
    // filter:  sepia(100%);
  }

  .settlement .el-cascader {
    width: 100%;
  }

  .settlement .el-input__inner {
    height: 98%;
    width: 100%;
  }

  .settlement .el-icon-arrow-down:before {
    color: #000;
    font-weight: bold;
  }

  .Details_collapse .el-collapse-item__header {
    font-size: calc(14vw*100/1920);
    font-weight: bold;
    color: #3d3d3d;
  }

  .Details_collapse .el-collapse-item__wrap {
    border-bottom: 0.1vw solid #cccc;
    position: relative;

    .collTextIcon {
      position: absolute;
    }
  }

  .Details_collapse .el-collapse-item__header {
    height: 4.07vw;

  }

  .Details_collapse .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);

  }

  .Details_collapse .el-collapse-item__arrow.is-active.el-icon-arrow-right:before {
    content: "\e6d8";
    font-weight: bold;
  }

  .Details_collapse .el-collapse-item__header:hover {
    color: #36563D !important;
  }

  .Details_collapse .el-icon-arrow-right:before {
    content: "\e6d9";
    // color: #000;
    font-weight: bold;
  }



  .address .el-table th.el-table__cell {
    background: #F4F4F4;
    color: #333;
    font-size: calc(14vw*100/1920);
  }

  .address .el-table td.el-table__cell {
    height: 4.12vw;
    box-sizing: border-box;
    padding: 0px;
    font-size: calc(14vw*100/1920);
  }

  .address .el-input__inner {
    width: 100%;
    border: none;
  }

  .el-upload-list--picture .el-upload-list__item-name {
    display: none;
  }

  .address .el-cascader {
    width: 100%;
  }

  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    border: 0.1vw solid #333;
  }

  .el-select .el-input__inner:focus {
    border: 0.1vw solid #333;
  }

  .el-select-dropdown__item.selected {
    color: #333;
  }

  .el-icon-plus:hover,
  .el-icon-minus:hover {
    color: #36563D !important;
  }

  .el-input__inner {
    border: 0.1vw solid #000;
    border-radius: 2px;
    font-size: calc(14vw*100/1920);
    color: #333;
    font-weight: normal;
  }

  .el-input__inner::placeholder {
    color: #666;
  }

  .el-select:hover .el-input__inner {
    border: 0.1vw solid #000;
    border-radius: 2px;
  }

  .el-input__inner:hover {
    border: 0.1vw solid #000;
    border-radius: 2px;
  }

  .el-icon-arrow-up:before {
    color: #000;
    font-weight: bold;
  }

  .Specification .el-input__inner {
    border: none !important;
  }

  .el-select-dropdown {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0);
    border: 0.1vw solid #333;
  }

  .el-popper .popper__arrow,
  .el-popper .popper__arrow::after {
    border-style: none;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #F5F5F5;
  }

  .el-select-dropdown__item.selected {
    font-weight: normal;
  }

  .icon_search .el-input__inner {
    border: none !important;
    background: #F4F3F0;
  }

  .icon_search .el-input__inner::placeholder {
    color: #666;
  }

  .icon_search .el-input__inner {
    color: #333;
  }

  .el-select-dropdown {
    margin-top: 0.25vw !important;
    border-radius: 0px 0px 2px 2px;
    z-index: 99 !important;
  }

  .el-input__inner {
    height: 2.1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-input__suffix {
    height: 1.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.2vw;
  }

  .el-icon-arrow-up:before {
    font-size: calc(14vw*100/1920) !important;
  }

  .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
  .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #000 !important;
  }

  input {
    outline: none;
  }

  .el-message-box__status.el-icon-warning {
    color: #333;
  }

  .el-button:focus,
  .el-button:hover {
    color: #333;
    background: #fff;
    border: 1px solid #000;
  }

  .el-message-box__btns button:nth-child(2) {
    background: #333 !important;
    color: #fff !important;
  }

  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #333;
    color: #fff;
  }

  .el-message-box__headerbtn .el-message-box__close:hover,
  .el-message-box__close:focus {
    color: #333;
  }

  .dian {
    width: 0.315vw;
    height: 0.315vw;
    border-radius: 50%;
    background-color: #36563D;
    margin-right: 0.315vw;
    position: absolute;
  }

  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;


  }

  #app::-webkit-scrollbar {
    width: 0;

  }

  .el-table .el-table__cell {
    text-align: center !important;
  }
}</style>
