import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '../views/forgot.vue')
  },
  {
    path:"/shoppingCart",
    name:'shoppingCart',
    component: () => import(/* webpackChunkName: "about" */ '../views/shoppingCart.vue')
  },
  {
    path:"/settlement",
    name:'settlement',
    component: () => import(/* webpackChunkName: "about" */ '../views/settlement.vue')
  },
  {
    path:"/classification",
    name:"classification",
    component:()=>import(/* webpackChunkName: "about" */ '../views/classification.vue')
  },
  {
    path:"/Details",
    name:"Details",
    component:()=>import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path:"/details2",
    name:"details2",
    component:()=>import(/* webpackChunkName: "about" */ '../views/details2.vue')
  },
  {
    path:"/help",
    name:"help",
    component:()=>import(/* webpackChunkName: "about" */ '../views/help.vue')
  },
  {
    path:"/translation",
    name:"translation",
    component:()=>import(/* webpackChunkName: "about" */ '../views/translation.vue')
  },
  {
    path:"/Story",
    name:"Story",
    component:()=>import(/* webpackChunkName: "about" */ '../views/Story.vue'),
    children:[
      {
        path:"/Story/about",//关于
        name:"about",
        component:()=>import(/* webpackChunkName: "about" */ '../components/about.vue'),
      },
      {
        path:"/Story/Advantage",//优势
        name:"Advantage",
        component:()=>import(/* webpackChunkName: "about" */ '../components/Advantage.vue'),
      },
      {
        path:"/Story/Brand",//故事
        name:"Brand",
        component:()=>import(/* webpackChunkName: "about" */ '../components/Brand.vue'),
      },

    ]
  },
  {
    path:"/CenterVIP",
    name:"CenterVIP",
    component:()=>import(/* webpackChunkName: "about" */ '../views/CenterVIP.vue'),
    redirect:'/CenterVIP/homePage',
    children:[
      {
        path:"/CenterVIP/homePage",//会员主页
        name:"homePage",
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/homePage.vue'),

      },
      {
        path:"/CenterVIP/order",//订单
        name:"order",
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/order.vue'),
      },
      {
        path:"/CenterVIP/ChangePas",//修改密码
        name:"ChangePas",
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/ChangePas.vue'),
      },
      {
        path:"/CenterVIP/Favorite",//收藏
        name:"Favorite",
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/Favorite.vue'),
      },
      {
        path:"/CenterVIP/Address",//地址
        name:"Address",
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/Address.vue'),
      },
      {
        path:"/CenterVIP/service",
        name:"service",//售后
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/service.vue'),
      },
      {
        path:"/CenterVIP/Details",
        name:"Details",//售后
        component:()=>import(/* webpackChunkName: "about" */ '../components/vip/Details.vue'),
      }

    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
