import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { axiosGet,axiosPost,axiosDel } from './api/request.js'
Vue.prototype.$axiosGet=axiosGet
Vue.prototype.$axiosPost=axiosPost
Vue.prototype.$axiosDel=axiosDel
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$loading = function (data){
  document.querySelector(".loading").style.display="flex"

  window.setTimeout(()=>{
    document.querySelector(".loading").style.display="none"
  },3000)
}

//注册get请求


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
