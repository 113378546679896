<template>
    <div class="top">
        <div class="top_Announcement">购物无忧 免邮送达</div>
        <!-- <div class="top_top" v-show="topShowsWatch"><span>中文</span> | <span>EN</span></div> -->
        <div class="top_content" v-show="topShowsWatch">
            <div @click="homeClick" style="cursor: pointer;" class="logoBox searchImg">
                <img src="../assets/newImage/eelhoe高端院线logo.png" alt="">
            </div>
            <div class="icon">
                <div class="icon_img">
                    <div class="icon_search" v-show="searchShows" pointer-events="none">
                        <el-input placeholder="请输入搜索内容" v-model="inputValue"></el-input>
                        <div class="icon_searchImg">
                            <img src="../assets/newImage/search.png" alt="" @click="searchShow">
                        </div>
                    </div>
                    <img src="../assets/newImage/search.png" alt="" @click="searchShow" class="searchImg">
                </div>
                <div class="icon_img">
                    <img src="../assets/newImage/admin.png" alt="" @click="login" class="searchImg">
                </div>
                <div class="icon_img">
                    <img src="../assets/newImage/shopCart.png" alt="" @click="cartClick" class="searchImg">
                </div>
            </div>
        </div>
        <!-- @mouseleave="listMouseOut()" -->
        <div class="top_nav"  @mouseleave="listMouseOut()">
            <div v-for="(item, i) in navData" :key="i" @mouseenter="mouseTopNav(item)"
                style="height: 3.2vw; display: flex;align-items: center;margin-right: 3.34vw;box-sizing: border-box;    white-space: nowrap;">
                <div class="top_nav_div" :class="navSelectId == item.id ? 'nav_select' : ''" @click="navClick(item)">{{
                    item.name }}</div>
            </div>
            <!-- v-show="listTableShow"   -->
            <div class="top_list" v-show="listTableShow" 
                :style="myHome == false ? 'padding:0;display:flex;justify-content: center;align-items: center;' : ''">
                <div class="top_list_img" v-show="myHome">
                    <div class="top_list_image">
                        <img src="../assets/newImage/分类导航图-护发美发.png" alt="">
                    </div>
                </div>

                <div class="top_list_text">
                    <div v-for="(item, i) in listTable" :key="i" :id="`Content${i}`"
                        :style="myHome == false ? 'margin-left:3vw;margin-right:3vw;display:flex;justify-content: center;align-items: center;height: 3.025vw;' : 'margin-left: 8.75vw;width:6.667vw;margin-bottom: 1.5vw;'">
                        <div class="textContentBox" :style="myHome == false ? 'margin-bottom:0' : ''">
                            <span class="textContent" @click="topNav(item, '')">{{ item.name }}</span>
                        </div>

                        <div class="content">
                            <div v-for="(ite, s) in item.list" :key="s" @click="topNav(ite, item)" @mouseenter="mouse(ite)"
                                @mouseleave="listOut()" style="height: 1.15vw;margin-bottom: 0.42vw;line-height: 1.15vw;">
                                <span
                                    :style="contentHover == ite.id ? 'color: #36563D;  border-bottom: 0.1vw solid #36563D;' : ''">
                                    {{ ite.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="me" v-show="listTableShow">
        </div>

        <div class="meBox" v-show="searchShows" pointer-events="none" @click="searchShows=false">
            <div class="me">
            </div>
        </div>

    </div>
</template>

<script>
import { navData } from '../assets/apiData/homeNav'
export default {
    props: {
        topShow: Boolean
    },
    data() {
        return {
            navData: [],
            navSelectId: null,
            topShowsWatch: true,
            listTableShow: false,
            listTable: [],
            searchShows: false,
            searchValue: "",
            urlData: '',
            contentHover: null,
            myHome: true,
            inputValue: ""
        };
    },
    watch: {
        topShow(n, o) {
            if (n == true) {
                this.topShowsWatch = false
                document.querySelector('.top_nav').style.position = "fixed"
                document.querySelector('.top_nav').style.top = "0"
            } else {
                this.topShowsWatch = true
                document.querySelector('.top_nav').style.position = "relative"

            }
        },
    },

    mounted() {
        console.log(this.topShow);
        this.navData = navData
        this.listTable = this.navData[0].list
    },

    methods: {
        mouseTopNav(value) {

            if (value.name == "品牌故事") {
                this.myHome = false
            } else {
                this.myHome = true
            }
            this.navSelectId = value.id
            this.listTableShow = true
            if (value.list.length > 0) {
                this.listTable = value.list
            } else {
                this.listTable = []
            }

        },
        listMouseOut() {
            this.listTableShow = false
            this.navSelectId = null
            this.listTable = []
        },
        searchShow() {
            this.searchShows = !this.searchShows


        },
        homeClick() {
            console.log(this.$route.path, "this.$route.path");
            if (this.$route.path != '/') {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                this.$router.push('/')

            }

        },
        login() {
            //需要判断是否登录
            if (this.$route.path != '/login') {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                this.$router.push('/login')
            }
        },
        //购物车
        cartClick() {
            if (this.$route.path != '/shoppingCart') {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                this.$router.push('/shoppingCart')
            }
        },
        //商品分类
        topNav(value, value2) {

            if (value.name != "关于eelhoe" && value.name != "我们的优势" && value.name != "品牌理念") {
                let str = []
                this.navData.forEach((vl) => {

                    if (vl.id == value.id) {
                        str = [vl.name]
                    } else {
                        vl.list.forEach((el) => {
                            if (el.id == value.id) {
                                str = [vl.name, el.name]
                            } else {
                                if (el.list != undefined) {
                                    el.list.forEach((ol) => {
                                        if (ol.id == value.id) {
                                            str = [vl.name, el.name, ol.name]
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
                str = str.join("-")
                if (this.urlData != str) {
                    document.getElementById("app").scrollTo({
                        top: 0,
                    });
                    this.$router.push({ path: "/classification", query: { value: str } })
                    // window.location.reload();
                    this.urlData = str
                }
            } else if (value.name == "关于eelhoe") {
                if (this.$route.path != '/Story/about') {
                    document.getElementById("app").scrollTo({
                        top: 0,
                    });
                    this.$router.push("/Story/about")
                }
            } else if (value.name == "我们的优势") {
                if (this.$route.path != '/Story/Advantage') {
                    document.getElementById("app").scrollTo({
                        top: 0,
                    });
                    this.$router.push("/Story/Advantage")
                }
            } else if (value.name == "品牌理念") {
                if (this.$route.path != '/Story/Brand') {
                    document.getElementById("app").scrollTo({
                        top: 0,
                    });
                    this.$router.push("/Story/Brand")
                }
            }
            this.listTableShow = false

        },
        navClick(val) {
            if (val.name != '品牌故事') {
                if (this.urlData != val.name) {
                    document.getElementById("app").scrollTo({
                        top: 0,
                    });
                    this.$router.push({ path: "/classification", query: { value: val.name } })
                    // window.location.reload();
                    this.urlData = val.name
                }
            } else {
                document.getElementById("app").scrollTo({
                    top: 0,
                });
                this.$router.push('/Story/about')
            }

        },
        //去重
        unique(arr) {
            return Array.from(new Set(arr));
        },
        mouse(val) {
            this.contentHover = val.id
        },
        listOut() {
            this.contentHover = null
        }
    },
};
</script>

<style lang="scss" scoped>
.top {
    width: 100vw;
    position: relative;

    .icon_search {
        position: absolute;
        width: 100vw;
        background: #F4F3F0;
        height: 3.125vw;
        top: 3.1vw;
        left: -74.4vw;
        z-index: 1001;
        display: flex;
        align-items: center;
        padding: 0 18.75vw;
        box-sizing: border-box;

        .icon_searchImg {
            width: 3.125vw;
            height: 3.125vw;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.02vw;
            box-sizing: border-box;

            cursor: pointer;

            img {
                width: 101%;
                height: 96%;
            }
        }

        .icon_searchImg:hover,
        .icon_searchImg:focus {
            background: #E5E5E5;
        }

    }

    .top_Announcement {
        width: 100%;
        height: 2.1vw;
        background: #222222;;
        color: #fff;
        font-size: calc(14vw*100/1920);
        text-align: center;
        line-height: 2.1vw;
    }

    .searchImg {
        z-index: 1001;
    }

    .top_top {
        width: 100vw;
        height: 2.15vw;
        line-height: 2.15vw;
        padding: 0 1.25vw;
        box-sizing: border-box;
        color: #c1c1c1;
        display: flex;
        justify-content: end;

        span {
            margin: 0 0.5vw;
            cursor: pointer;
        }
    }

    .top_content {
        width: 100vw;
        height: 3.13vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(85vw*100/1920);
        font-weight: 600;
        font-family: fangsong;
        position: relative;
        background-color: #fff;

        .logoBox {
            width: 7.19vw;
            height: 100%;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: 2vw;
            }
        }

        .icon {
            position: absolute;
            display: flex;
            height: 3.13vw;
            justify-content: space-evenly;
            align-items: center;
            top: 0;
            right: 18.855vw;

            .icon_img {
                width: 1vw;
                height: 1vw;
                display: flex;
                align-items: center;
                margin-right: 1.25vw;


                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .top_nav {
        width: 100vw;
        height: 3.13vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24vw;
        box-sizing: border-box;
        font-size: calc(14vw*100/1920);
        font-weight: 400;
        background-color: #fff;
        z-index: 999;
        border-bottom: 0.1vw solid #E5E5E5;
        position: relative;
        padding-top: 1.16vw;
        box-sizing: border-box;

        .top_nav_div {
            height: 60%;
            display: flex;
            // align-items: center;
            cursor: pointer;
            color: #333;
            font-size: calc(14vw*100/1920);
            box-sizing: border-box;

        }

        .nav_select {
            color: #36563D;
            border-bottom: 2px solid #36563D;
            box-sizing: border-box;
        }

        .top_list {
            position: absolute;
            width: 100%;
            background: #ffffff;
            top: 3.13vw;
            z-index: 9;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            padding: 1.25vw;
            padding-left: 24vw;
            box-sizing: border-box;
     

            .top_list_text {
                // width: 70%;
                // height: 100%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;

                #Content0 {
                    margin-left: 1.25vw !important;
                }

                .textContentBox {
                    height: 1.15vw;
                    line-height: 1.15vw;
                    margin-bottom: 0.73vw;
                }

                .textContent {
                    font-size: calc(14vw*100/1920);
                    font-weight: bold;
                    cursor: pointer;
                    color: #333;

                }

                .content {
                    margin-top: 0.73vw;
                    white-space: nowrap;
                    font-size: calc(14vw*100/1920);
                    color: #333;
                    cursor: pointer;
                    font-weight: normal;
                }

                .textContent:hover {
                    color: #36563D;
                    border-bottom: 0.1vw solid #36563D;
                }
            }

            .top_list_img {
                // width: 30%;
                height: 100%;

                .top_list_image {
                    width: 10.94vw;
                    height: 14.6vw;
                    border-radius: 8px;
                    background-image: linear-gradient(to top, orange, rgb(130, 159, 238));
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    // margin-left: 6.25vw;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .meBox {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0vw;
        left: 0;
        z-index: 1000;
    }

    .me {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 6.2vw;
        left: 0;
        background: #00000073;
        z-index: 998;
    }

}
</style>