import c from '../newImage/彩妆.svg'
import h from '../newImage/护肤.svg'
import f from '../newImage/护发.svg'
import r from '../newImage/染发.svg'
import s from '../newImage/身体护理.svg'
import m from '../newImage/美体塑形.svg'
import j from '../newImage/保健护理.svg'
import bj1 from '../newImage/男士系列.png'
import bj2 from '../newImage/口腔护理.png'
import cp1 from '../newImage/EELHOE 神经酰胺紧致精华.png'
import cp2 from '../newImage/EELHOE 红茶紧致塑颜面霜.png'
import cp3 from '../newImage/EELHOE-蓝兰花面部护理油.png'
import cp4 from '../newImage/EELHOE 牙齿亮白修护牙膏.png'
import cp5 from '../newImage/EELHOE 高光液体眼影.png'
import cp6 from '../newImage/EELHOE 新款小蘑菇眼影.png'
import cp7 from '../newImage/EELHOE 棉签唇彩.png'
import cp9 from '../newImage/EELHOE 橡皮擦遮瑕笔.png'
import man1 from '../newImage/EELHOE 男士发际线修容粉.png'
import man2 from '../newImage/EELHOE 胡须生长喷雾.png'
import ya1 from '../newImage/EELHOE 牙齿亮白修护牙膏.png'
import ya2 from '../newImage/EELHOE 牙齿洁白牙膏.png'
import jiu from '../newImage/cpCart.png'
import allcp1 from '../newImage/EELHOE 面部保湿霜.png'
import cp from '../newImage/cp10.png'
import cp11 from '../newImage/cp11.png' 

export  const navData=[
    {
        id:0,
        name:"彩妆",
        list:[
            {
                id:"0-1",
                name:"脸部",
                list:[
                    {
                        id:"0-1-1",
                        name:"妆前乳"
                    },
                    {
                        id:"0-1-2",
                        name:"粉底"
                    },
                    {
                        id:"0-1-3",
                        name:"蜜粉"
                    },
                    {
                        id:"0-1-4",
                        name:"高光"
                    },
                    {
                        id:"0-1-5",
                        name:"腮红"
                    }
                ]
            },
            {
                id:"0-2",
                name:"眼部",
                list:[
                    {
                        id:"0-2-1",
                        name:"眉笔"
                    },
                    {
                        id:"0-2-2",
                        name:"眼线"
                    },              {
                        id:"0-2-3",
                        name:"眼影"
                    },              {
                        id:"0-2-4",
                        name:"睫毛膏"
                    }   
                ]
            },
            {
                id:"0-3",
                name:"唇部", 
                list:[
                    {
                        id:"0-3-1",
                        name:"唇膏"
                    },
                    {
                        id:"0-3-2",
                        name:"唇釉"
                    },
                    {
                        id:"0-3-3",
                        name:"唇彩"
                    },
                    {
                        id:"0-3-4",
                        name:"护唇膏"
                    },
                ] 
            },
        ]
    },
    {
        id:1,
        name:"香水",
        list:[
            {
                id:"1-0",
                name:"产品系列",
                list:[
                    {
                        id:"1-0-1",
                        name:"精油香水系列"
                    },  
                    {
                        id:"1-0-2",
                        name:"自然清爽系列"
                    },  
                    {
                        id:"1-0-3",
                        name:"淡雅花香系列"
                    },  
                ]
            }
        ]  
    },
    {
        id:2,
        name:"护肤",
        list:[
            {
                id:"2-0",
                name:"护肤步骤",
                list:[
                    {
                        id:"2-0-1",
                        name:"卸妆洁面"
                    },
                    {
                        id:"2-0-2",
                        name:"化妆水"
                    },
                    {
                        id:"2-0-3",
                        name:"精华"
                    },
                    {
                        id:"2-0-4",
                        name:"乳液"
                    },
                    {
                        id:"2-0-5",
                        name:"面霜"
                    },
                    {
                        id:"2-0-6",
                        name:"面膜"
                    },
                    {
                        id:"2-0-7",
                        name:"眼唇护理"
                    },
                    {
                        id:"2-0-8",
                        name:"防护隔离"
                    },
                    {
                        id:"2-0-9",
                        name:"手部身体护理"
                    },
        
                ]
            },
            {
                id:"2-1",
                name:"肌理需求",
                list:[
                    {
                        id:"2-1-1",
                        name:'美白淡斑'
                    },
                    {
                        id:"2-1-2",
                        name:'补水保湿'
                    },
                    {
                        id:"2-1-3",
                        name:'紧致抗老'
                    },
                    {
                        id:"2-1-4",
                        name:'肌肤清洁'
                    },
                    {
                        id:"2-1-5",
                        name:'改善肌底'
                    },
                    {
                        id:"2-1-6",
                        name:'防晒隔离'
                    }
                ]
            },
            {
                id:"2-2",
                name:"产品成分",
                list:[
                    {
                        id:"2-2-1",
                        name:'植物类'
                    },
                    {   
                        id:"2-2-2",
                        name:'油类'
                    },
                    {
                        id:"2-2-3",
                        name:'其他'
                    }
                ]
            },
        ]
    },
    {
        id:3,
        name:"护发美发",
        list:[
           {
            id:"3-0",
            name:"美发步骤",
            list:[
                {
                    id:"3-0-1",
                    name:"洗发"
                },
                {
                    id:"3-0-2",
                    name:"护发"
                },
                {
                    id:"3-0-3",
                    name:"染发"
                },
                {
                    id:"3-0-4",
                    name:"烫发"
                },
                {
                    id:"3-0-5",
                    name:"定型"
                },
            ]
           },
           {
            id:"3-1",
            name:"功效需求",
            list:[
                {
                    id:"3-1-1",
                    name:"蓬松补水"
                },
                {
                    id:"3-1-2",
                    name:"柔顺修护"
                },
                {
                    id:"3-1-3",
                    name:"去屑止痒"
                },
                {
                    id:"3-1-4",
                    name:"防脱生发"
                },
            ]
           },
           {
            id:"3-2",
            name:"产品成分",
            list:[
                {
                    id:"3-2-1",
                    name:'植物类'
                },
                {   
                    id:"3-2-2",
                    name:'油类'
                },
                {
                    id:"3-2-3",
                    name:'其他'
                }
            ]
           }
        ]
    },
    {
       id:4,
       name:"健康保健",
       list:[
        {
            id:"3-1",
            name:"产品类型",
            list:[
                {
                    id:"3-1-1",
                    name:"皮肤护理"
                },
                {
                    id:"3-1-2",
                    name:"眼耳鼻护理"
                },
                {
                    id:"3-1-3",
                    name:"手足护理"
                },
                {
                    id:"3-1-4",
                    name:"身体护理"
                },
                {
                    id:"3-1-5",
                    name:"美体护理"
                },
                {
                    id:"3-1-6",
                    name:"健康护理"
                },
                {
                    id:"3-1-7",
                    name:"缓解疼痛"
                },
                {
                    id:"3-1-8",
                    name:"居家健康"
                },
            ]
        },
        {
            id:"4-2",
            name:"健康需求",
            list:[
                {
                    id:"4-2-1",
                    name:"消炎止痛"
                },
                {
                    id:"4-2-2",
                    name:"抑菌止痒"
                },
                {
                    id:"4-2-3",
                    name:"活血化瘀"
                },
                {
                    id:"4-2-4",
                    name:"强健筋骨"
                },
                {
                    id:"4-2-5",
                    name:"祛风散寒"
                },
                {
                    id:"4-2-6",
                    name:"身体疾病预防与治疗"
                },
            ]
        },
        {
            id:"4-3",
            name:"产品成分",
            list:[
                {
                    id:"4-3-1",
                    name:"中药成分"
                },
                {
                    id:"4-3-2",
                    name:"其他"
                }
            ]
        },
       ]
    },
    {
        id:5,
        name:"男士系列",
        list:[
            {
                id:"5-1",
                name:"产品类型",
                list:[
                    {
                        id:"5-1-1",
                        name:"男士彩妆"
                    },
                    {
                        id:"5-1-2",
                        name:"男士护肤"
                    },
                    {
                        id:"5-1-3",
                        name:"男士护发"
                    },
                    {
                        id:"5-1-4",
                        name:"男士保健"
                    },
                ]
            }
        ]
    },
    {
        id:6,
        name:"口腔护理",
        list:[
            {
                id:"6-1",
                name:"产品类型",
                list:[
                    {
                        id:"6-1-1",
                        name:"牙膏"
                    },
                    {
                        id:"6-1-2",
                        name:"牙粉"
                    },
                    {
                        id:"6-1-3",
                        name:"牙贴"
                    },
                    {
                        id:"6-1-4",
                        name:"牙笔"
                    },     
                    {
                        id:"6-1-5",
                        name:"洁牙液"
                    },  
                    {
                        id:"6-1-6",
                        name:"牙套"
                    },
                ]
            },
            {
                id:"6-2",
                name:"护理步骤",
                list:[
                    {
                        id:"6-2-1",
                        name:"口腔清洁"
                    },
                    {
                        id:"6-2-2",
                        name:"牙龈修护"
                    },
                    {
                        id:"6-2-3",
                        name:"牙齿美白"
                    },
                    {
                        id:"6-2-4",
                        name:"牙齿保护"
                    },
                ]
            }
        ]
    },
    {
        id:7,
        name:"美容工具",
        list:[
            {
                id:"7-1",
                name:"产品类型",
                list:[
                    {
                        id:"7-1-1",
                        name:"护甲美甲"
                    },
                    {
                        id:"7-1-2",
                        name:"美妆工具"
                    },
                    {
                        id:"7-1-3",
                        name:"美发工具"
                    },
                    {
                        id:"7-1-4",
                        name:"美容仪器"
                    },     
                    {
                        id:"7-1-5",
                        name:"假牙用品"
                    },  
          
                ]
            },
        ]
    },
    {
        id:8,
        name:"品牌故事",
        list:[
            {
                id:"8-1",
                name:"关于eelhoe"
            },
            {
                id:"8-2",
                name:"我们的优势"
            },
            {
                id:"8-3",
                name:"品牌理念"
            },
        ]
    },
    
] 
export  const newShop=[
    {
        id:0,
        name:"EELHOE 神经酰胺紧致精华",
        title:"深层养护淡化皱纹补水滋润",
        img:cp1,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:1,
        name:"EELHOE 红茶紧致塑颜面霜",
        title:"抗皱收缩毛孔补水嫩肤提拉紧致",
        img:cp2,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:2,
        name:"EELHOE 蓝兰花面部护理油",
        title:"皮肤抗皱滋润修护紧致细纹",
        img:cp3,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:3,
        name:"EELHOE 牙齿亮白修护牙膏",
        title:"口腔清洁牙釉质修护清新口气祛牙渍",
        img:cp4,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:4,
        name:"EELHOE 高光液体眼影",
        title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
        img:cp5,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    

    {
        id:5,
        name:"EELHOE 神经酰胺紧致精华",
        title:"深层养护淡化皱纹补水滋润",
        img:cp1,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:6,
        name:"EELHOE 红茶紧致塑颜面霜",
        title:"抗皱收缩毛孔补水嫩肤提拉紧致",
        img:cp2,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:7,
        name:"EELHOE 蓝兰花面部护理油",
        title:"皮肤抗皱滋润修护紧致细纹",
        img:cp3,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:8,
        name:"EELHOE 牙齿亮白修护牙膏",
        title:"口腔清洁牙釉质修护清新口气祛牙渍",
        img:cp4,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:9,
        name:"EELHOE 高光液体眼影",
        title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
        img:cp5,
        price:"￥10",
        unitPrice:"￥18.5"
    },
]
export  const beauty=[
    {
        id:0,
        name:"彩妆",
        img:c,
        list:[
            {
                id:0,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:1,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:2,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:3,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
            {
                id:4,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:5,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:6,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:7,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
        ]
    },
    {
        id:1,
        name:"护肤",
        img:h,
        list:[
            {
                id:0,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:1,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:2,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:3,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
            {
                id:4,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:5,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:6,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:7,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
        ]
    },
    {
        id:2,
        name:"护发",
        img:f,
        list:[
            {
                id:0,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:1,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:2,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:3,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
            {
                id:4,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:5,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:6,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:7,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
        ]
    },
    {
        id:3,
        name:"染发",
        img:r,
        list:[
            {
                id:0,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:1,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:2,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:3,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
            {
                id:4,
                name:"EELHOE 橡皮擦遮瑕笔",
                price:"￥6",
                unitPrice:"￥6",
                title:"遮盖黑眼圈斑点痘印防水持久不脱妆",
                img:cp9
            },
            {
                id:5,
                name:"EELHOE 高光液体眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
                img:cp5
            },
            {
                id:6,
                name:"EELHOE 棉签唇彩",
                price:"￥6",
                unitPrice:"￥6",
                title:"一次性便捷补妆神器亚马逊爆款",
                img:cp7
            },
            {
                id:7,
                name:"EELHOE 新款小蘑菇眼影",
                price:"￥6",
                unitPrice:"￥6",
                title:"美妆气垫渐变眼影 初学者眼影",
                img:cp6
            },
        ]
    },
]
export  const health=[
    {
        id:0,
        name:"面部护理",
        img:h,
        list:[
            {
                id:0,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:1,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:2,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:3,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },
            {
                id:4,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:5,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:6,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:7,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },

        ]
    },
    {
        id:1,
        name:"身体护理",
        img:s,
        list:[
            {
                id:0,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:1,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:2,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:3,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },
            {
                id:4,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:5,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:6,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:7,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },

        ]
    },
    {
        id:2,
        name:"美体塑型",
        img:m,
        list:[
            {
                id:0,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:1,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:2,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:3,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },
            {
                id:4,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:5,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:6,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:7,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },

        ]
    },
    {
        id:3,
        name:"保健护理",
        img:j,
        list:[
            {
                id:0,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:1,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:2,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:3,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },
            {
                id:4,
                name:"EELHOE 面部保湿霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"紧致淡化皱纹补水滋润易吸收改善干燥",
                img:allcp1
            },
            {
                id:5,
                name:"EELHOE 神经酰胺紧致精华",
                price:"￥6",
                unitPrice:"￥6",
                title:"深层养护淡化皱纹补水滋润紧致肌肤",
                img:cp1
            },
            {
                id:6,
                name:"EELHOE 蓝兰花面部护理油",
                price:"￥6",
                unitPrice:"￥6",
                title:"皮肤抗皱滋润修护紧致细纹法令纹",
                img:cp3
            },
            {
                id:7,
                name:"EELHOE 红茶紧致塑颜面霜",
                price:"￥6",
                unitPrice:"￥6",
                title:"抗皱收缩毛孔补水嫩肤提拉紧致肌肤",
                img:cp2
            },

        ]
    },
]
// 男士系列  口腔护理
export const Series=[
    {
        id:0,
        name:'男士系列',
        image:bj1,
        x:'right',
        list:[
            {
                id:0,
                list:[
                    {
                        id:0,
                        name:"EELHOE 男士发际线修容粉",
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"填充发际线高额头神器便携眉粉",
                        img:man1
                    },
                    {
                        id:1,
                        name:"EELHOE 胡须生长喷雾",
                        price:"￥233",
                        unitPrice:"￥199",
                        title:"滋养保湿胡须促进胡子增长浓密液",
                        img:man2
                    }
                ]
            },
            {
                id:1,
                list:[
                    {
                        id:2,
                        name:'商品3',
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"非常好用的！！！",
                        img:cp1
                    },
                    {
                        id:3,
                        name:'商品4',
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"非常好用的！！！",
                        img:cp2
                    }
                ]
            }
        ]
    },
    {
        id:1,
        name:"口腔护理",
        image:bj2,
        x:'left',
        list:[
            {
                id:0,
                list:[
                    {
                        id:0,
                        name:"EELHOE 牙齿亮白修护牙膏",
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"口腔清洁牙釉质修护清新口气祛牙渍",
                        img:ya1
                    },
                    {
                        id:1,
                        name:"EELHOE 牙齿洁白牙膏",
                        price:"￥233",
                        unitPrice:"￥199",
                        title:"洁白美牙去除牙渍异味口腔护理",
                        img:ya2
                    }
                ]
            },
            {
                id:1,
                list:[
                    {
                        id:2,
                        name:'商品3',
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"非常好用的！！！",
                        img:cp1
                    },
                    {
                        id:3,
                        name:'商品4',
                        price:"￥199",
                        unitPrice:"￥199",
                        title:"非常好用的！！！",
                        img:cp2
                    }
                ]
            }
        ]
    }
]
//热卖
export const hot=[
    {
        id:0,
        name:"EELHOE 神经酰胺紧致精华",
        title:"深层养护淡化皱纹补水滋润",
        img:cp1,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:1,
        name:"EELHOE 红茶紧致塑颜面霜",
        title:"抗皱收缩毛孔补水嫩肤提拉紧致",
        img:cp2,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:2,
        name:"EELHOE 蓝兰花面部护理油",
        title:"皮肤抗皱滋润修护紧致细纹",
        img:cp3,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:3,
        name:"EELHOE 牙齿亮白修护牙膏",
        title:"口腔清洁牙釉质修护清新口气祛牙渍",
        img:cp4,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:4,
        name:"EELHOE 高光液体眼影",
        title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
        img:cp5,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    

    {
        id:5,
        name:"EELHOE 神经酰胺紧致精华",
        title:"深层养护淡化皱纹补水滋润",
        img:cp1,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:6,
        name:"EELHOE 红茶紧致塑颜面霜",
        title:"抗皱收缩毛孔补水嫩肤提拉紧致",
        img:cp2,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:7,
        name:"EELHOE 蓝兰花面部护理油",
        title:"皮肤抗皱滋润修护紧致细纹",
        img:cp3,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:8,
        name:"EELHOE 牙齿亮白修护牙膏",
        title:"口腔清洁牙釉质修护清新口气祛牙渍",
        img:cp4,
        price:"￥10",
        unitPrice:"￥18.5"
    },
    {
        id:9,
        name:"EELHOE 高光液体眼影",
        title:"防水细闪高光卧蚕提亮泪眼妆珠光闪片",
        img:cp5,
        price:"￥10",
        unitPrice:"￥18.5"
    },
]

//底部信息
export const bottom=[
    {
        id:0,
        name:"获得帮助",
        url:"",
        list:[
            {
                id:0,
                name:"订单状态",
                url:""
            },
            {
                id:1,
                name:"配送方式",
                url:""
            },
            {
                id:2,
                name:"退换货政策",
                url:""
            },
        ]
    },
    {
        id:1,
        name:"品牌故事",
        url:"",
        list:[
            {
                id:0,
                name:"关于eelhoe",
                url:""
            },
            {
                id:0,
                name:"我们的优势",
                url:""
            },
            {
                id:0,
                name:"品牌理念",
                url:""
            }
        ]
    }
]
//购物车信息
export const cartTable=[
    {
        id:0,
        image:jiu,
        name:"EELHOE 生姜精油紧致纤体瘦大肚子按摩出油塑形液收腹塑身按摩精油",
        title:"商品描述",
        specification:"10ml*5pcs",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"10ml*5pcs"
            }
        ]
    },
    {
        id:1,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:2,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:3,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:4,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:5,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:6,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:false,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
    {
        id:7,
        image:jiu,
        name:"商品名称",
        title:"商品描述",
        specification:"红色",
        price:"￥1888.88",
        num:1,
        unitPrice:"￥1888.88",
        selected:false,
        Translation:true,
        option:[
            {
                id:0,
                name:"红色"
            }
        ]
    },
   
]
//收货信息
export const addressTable=[
    {
        id:0,
        address:'林先生，123****4567，中国 广东省 汕头市 龙湖区 长平路95号华润大厦22楼',
        Default:true,
    },
    {
        id:1,
        address:'林先生，123****4567，中国 广东省 汕头市 龙湖区 长平路95号华润大厦22楼',
        Default:false,
    },
    {
        id:3,
        address:'林女士，132****8910，中国 广东省 汕头市 龙湖区 长平路95号华润大厦22楼',
        Default:false,
    }
]
//结算清单
export const listTable=[
    {
        id:0,
        name:"EELHOE 生姜精油紧致纤体瘦大肚子按摩出油塑形液收腹塑身按摩精油",
        img:jiu,
        specification:"规格：10ml*5pcs",
        price:"￥130,572.22",
        num:100000,
        discount:"￥1,305,572.19",
        total:"￥12,345,678.09"

    },
    {
        id:2,
        name:"EELHOE 生姜精油紧致纤体瘦大肚子按摩出油塑形液收腹塑身按摩精油",
        img:jiu,
        specification:"规格：10ml*5pcs",
        price:"￥130,572.22",
        num:100000,
        discount:"￥1,305,572.19",
        total:"￥12,345,678.09"

    },
    {
        id:3,
        name:"EELHOE 生姜精油紧致纤体瘦大肚子按摩出油塑形液收腹塑身按摩精油",
        img:jiu,
        specification:"规格：10ml*5pcs",
        price:"￥130,572.22",
        num:100000,
        discount:"￥1,305,572.19",
        total:"￥12,345,678.09"

    },
]
//商品分类
export const shopList=[
    {
        id:0,
        name:'EELHOE 面部保湿霜 ',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:"￥1888.88",
    },
    {
        id:1,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:199,
        img:cp1,
        unitPrice:"￥1888.88",
    },
    {
        id:2,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:188,
        img:cp2,
        unitPrice:"￥1888.88",
    },
    {
        id:3,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:"￥1888.88",
    },
    {
        id:4,
        name:'EELHOE 面部保湿霜 ',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:"￥1888.88",
    },
    {
        id:5,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:1888,
        img:cp1,
        unitPrice:"￥1888.88",
    },
    {
        id:6,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:1888,
        img:cp2,
        unitPrice:"￥1888.88",
    },
    {
        id:7,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:"￥1888.88",
    },
    {
        id:8,
        name:'EELHOE 面部保湿霜',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:"￥1888.88",
    },
    {
        id:9,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:1888,
        img:cp1,
        unitPrice:"￥1888.88",
    },
    {
        id:10,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:188,
        img:cp2,
        unitPrice:''
    },
    {
        id:11,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:''
    },
    {
        id:12,
        name:'EELHOE 面部保湿霜 ',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:"￥1888.88",
    },
    {
        id:13,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:199,
        img:cp1,
        unitPrice:"￥1888.88",
    },
    {
        id:14,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:188,
        img:cp2,
        unitPrice:"￥1888.88",
    },
    {
        id:15,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:"￥1888.88",
    },
    {
        id:16,
        name:'EELHOE 面部保湿霜 ',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:''
    },
    {
        id:17,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:199,
        img:cp1,
        unitPrice:''
    },
    {
        id:18,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:188,
        img:cp2,
        unitPrice:''
    },
    {
        id:19,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:''
    },
]
export const bottomHot=[
    {
        id:0,
        name:'EELHOE 面部保湿霜 ',
        title:"紧致淡化皱纹补水滋润易吸收改善干燥",
        img:'空',
        price:1888,
        img:allcp1,
        unitPrice:"￥1888.88",
    },
    {
        id:1,
        name:'EELHOE 神经酰胺紧致精华',
        title:"深层养护淡化皱纹补水滋润紧致肌肤",
        img:'空',
        price:199,
        img:cp1,
        unitPrice:"￥1888.88",
    },
    {
        id:2,
        name:'EELHOE 蓝兰花面部护理油',
        title:"皮肤抗皱滋润修护紧致细纹法令纹护肤油",
        img:'空',
        price:188,
        img:cp2,
        unitPrice:"￥1888.88",
    },
    {
        id:3,
        name:'EELHOE 红茶紧致塑颜面霜',
        title:"抗皱收缩毛孔补水嫩肤提拉紧致面部肌肤",
        img:'空',
        price:1888,
        img:cp3,
        unitPrice:"￥1888.88",
    },
]
export const orderData={
    //待付款
    '待付款':[
        {
            tracking:"202312181626",
            time:"2023-12-18 16:26",
            list:[
                {
                    id:0,
                    img:cp,
                    name:"EELHOE 生姜精油紧致纤体瘦大肚子 按摩出油塑形液收腹塑身按摩精油",
                    Specification: "规格：10ml*5pcs",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"100000"
                },
                {
                    id:1,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                },

            ]
        }
    ],
    //待发货
    '待发货':[
        {
            tracking:"202312171626",
            time:"2023-12-17 16:26",
            list:[
                {
                    id:0,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                }
            ] 
        }
    ],
    //已发货
    '已发货':[
        {
            tracking:"202312161626",
            time:"2023-12-16 16:26",
            list:[
                {
                    id:0,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                }
            ] 
        }
    ],
    //已完成
    '已完成':[
        {
            tracking:"202312151626",
            time:"2023-12-15 16:26",
            list:[
                {
                    id:0,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                },
                {
                    id:0,
                    img:cp,
                    name:"EELHOE 生姜精油紧致纤体瘦大肚子 按摩出油塑形液收腹塑身按摩精油",
                    Specification: "规格：10ml*5pcs",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"100000"
                },
            ] 
        }
    ],
    //取消
    '已取消':[
        {
            tracking:"202312141626",
            time:"2023-12-14 16:26",
            list:[
                {
                    id:0,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                }
            ] 
        }
    ],
    '已退款':[
        {
            tracking:"202312181626",
            time:"2023-12-18 16:26",
            list:[
                {
                    id:0,
                    img:cp,
                    name:"EELHOE 生姜精油紧致纤体瘦大肚子 按摩出油塑形液收腹塑身按摩精油",
                    Specification: "规格：10ml*5pcs",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"100000"
                },
                {
                    id:1,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                },

            ]
        }
    ]
}
export const serviceData={
    '已退款':[
        {
            tracking:"202312181626",
            time:"2023-12-18 16:26",
            list:[
                {
                    id:0,
                    img:cp,
                    name:"EELHOE 生姜精油紧致纤体瘦大肚子 按摩出油塑形液收腹塑身按摩精油",
                    Specification: "规格：10ml*5pcs",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"100000"
                },
                {
                    id:1,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                },

            ]
        }
    ],
         //已发货
    '已发货':[
        {
            tracking:"202312161626",
            time:"2023-12-16 16:26",
            list:[
                {
                    id:0,
                    img:cp11,
                    name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                    Specification: "颜色：星闪粉黛",
                    price: "￥130,572.22",
                    actual:"￥130,572.22",
                    num:"1"
                }
            ] 
        }
    ],
        //已完成
    '已完成':[
            {
                tracking:"202312151626",
                time:"2023-12-15 16:26",
                list:[
                    {
                        id:0,
                        img:cp11,
                        name: "EELHOE 高光液体眼影 防水细闪高光 卧蚕提亮泪眼妆珠光闪片眼影",
                        Specification: "颜色：星闪粉黛",
                        price: "￥130,572.22",
                        actual:"￥130,572.22",
                        num:"1"
                    }
                ] 
            }
    ],
}
// export default [navData ,newShop]